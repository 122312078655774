import axios from '@/libs/api.request'

export const getApplianceChangeAPI = () => {
  return axios.request({
    url: 'appliance-change',
    method: 'get'
  })
}

export const getApplianceChangeByIdAPI = (id) => {
  return axios.request({
    url: 'appliance-change/' + id,
    method: 'get'
  })
}

export const createApplianceChangeAPI = (credentials) => {
  return axios.request({
    url: 'appliance-change',
    data: credentials,
    method: 'post'
  })
}
