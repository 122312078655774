<template>
  <div v-if="userGroup == 'administrator' || userGroup == 'manage'">
    <CCard>
      <CCardHeader>
        <strong>ຂໍ້ມູນປະຫວັດການການປ່ຽນອຸປະກອນ</strong>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="4">
            <CCard border-color="success">
              <CCardHeader>ກະລຸນາປ້ອນຂໍ້ມູນ</CCardHeader>
              <CCardBody>
                <ValidationObserver v-slot="{ invalid }">
                  <CForm ref="form" :model="form" @submit.prevent="handleChange">
                    <CRow>
                      <CCol sm="12">
                        <validation-provider rules="required">
                          <div class="form-group">
                            <label>ເລກ Serial ເຄື່ອງເກົ່າ :</label>
                            <v-select
                              prop="appl_serial_old"
                              v-model="form.appl_serial_old"
                              :options="applList"
                              :reduce="appl_serial => appl_serial.appl_serial"
                              label="appl_serial"
                            />
                          </div>
                        </validation-provider>
                      </CCol>
                      <CCol sm="12">
                        <validation-provider rules="required">
                          <div class="form-group">
                            <label>ເລກ Serial ເຄື່ອງໃໝ່ :</label>
                            <v-select
                              prop="appl_serial_new"
                              v-model="form.appl_serial_new"
                              :options="applList"
                              :reduce="appl_serial => appl_serial.appl_serial"
                              label="appl_serial"
                            />
                          </div>
                        </validation-provider>
                      </CCol>
                      <CCol sm="12">
                        <CButton type="submit" :disabled="invalid" color="info" class="float-right">
                          <CIcon name="cil-save" />ບັນທຶກປ່ຽນອຸປະກອນ
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </ValidationObserver>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol sm="8">
            <CDataTable
              :items="items"
              :fields="fields"
              :loading="loading"
              hover
              sorter
              :table-filter="{placeholder: 'ຄົ້ນຫາ...', label: ' ', align: 'right'}"
              :items-per-page="20"
              pagination
            ></CDataTable>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import {
  getApplianceChangeAPI,
  createApplianceChangeAPI,
} from "@/api/applianceChange";
import { getApplianceAPI } from "@/api/appliance";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { decrypt } from "@/libs/crypto";
export default {
  name: "ChangeAppliance",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      userGroup: decrypt(this.$store.getters.getUserGroup),
      loading: true,
      items: [],
      fields: [
        { key: "id", label: "ລໍາດັບ" },
        { key: "change_date", label: "ວັນທີ່ປ່ຽນ" },
        { key: "appl_serial_old", label: "Serial ເກົ່າ" },
        { key: "appl_serial_new", label: "Serial ໃໝ່" },
        { key: "change_by", label: "ຜູ້ປ່ຽນ" },
      ],
      form: {
        appl_serial_old: "",
        appl_serial_new: "",
      },
      applList: [],
    };
  },
  mounted() {
    this.initialGetData();
  },
  methods: {
    // Load data  document income
    initialGetData() {
      setTimeout(() => {
        this.loading = false;
      }, 5000);
      getApplianceChangeAPI().then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
      getApplianceAPI().then((response) => {
        this.applList = response.data.data;
      });
    },

    handleChange() {
      let credentials = {
        appl_serial_old: this.form.appl_serial_old,
        appl_serial_new: this.form.appl_serial_new,
        change_by: decrypt(this.$store.getters.getFullName),
      };
      createApplianceChangeAPI(credentials)
        .then((response) => {
          this.$awn.success(response.data.message);
          this.initialGetData();
          this.form.appl_serial_old = "";
          this.form.appl_serial_new = "";
        })
        .catch((error) => {
          this.$awn.alert(error.response.data.message);
        });
    },
  },
};
</script>
